@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.page {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}


.container.contact-page,
.container.about-page,
.container.portfolio-page {

  h1 {
    font-size: 53px;
    font-family: ' Open Sans ', sans-serif;
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  p {
    font-size: 13px;
    color: #fff;
    font-family: ' Open Sans ', sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }
}

@include media-breakpoint-up(md) {
  .page {
    width: 100%;
    height: 100%;
    margin-top: 150px;
    margin-bottom: 150px;
  }
}

@include media-breakpoint-up(lg) {
  .page {
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}



