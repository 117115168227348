@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.Footer {

  background: #181818;
  padding-bottom: 10px;

  span {
    text-align: center;
    color: rgb(77, 77, 78);
  }


  ul {
    margin-right: 0;
    margin-left: 0;
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;

    li {
      a {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        font-size: 20px;
        line-height: 16px;
        color: #4d4d4e;

        &:hover svg {
          color: #ffd700;
        }
      }
    }
  }
}
