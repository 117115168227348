header {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 56px;
  background: #181818;
  height: auto;
}

.nav-bar {
  a {
    display: block;
    width: 100%;
    height: 100%;
  }


  .logo {
    display: block;
    padding: 8px 0;

    a {
      width: 24px;
    }

    img {
      display: block;
      margin: 8px 0;
      width: 24px;

      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 1px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }
}
