@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';


.portfolio {

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 32px;
    width: 100%;

  }
  .ratio-card {
    --bs-aspect-ratio: 100%;
  }

  .grid-item {
    background-color: #023048;
    //aspect-ratio: 16/9;
  }

  .grid1 {
    grid-area: 1 / 1 / 2 / 2;
  }

  .grid2 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .grid3 {
    grid-area: 3 / 1 / 4 / 2;
  }

  .grid4 {
    grid-area: 4 / 1 / 5 / 2;
  }

  .grid5 {
    grid-area: 5 / 1 / 6 / 2;
  }

  .grid6 {
    grid-area: 6 / 1 / 7 / 2;
  }


  .card {
    background-color: transparent;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    width: 100%;
    height: 100%;
    border: none;
  }

  .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: var(--bs-border-radius);
  }
  .card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-front {
    background-color: var(--bs-body-bg);
    color: black;
    border-radius: var(--bs-border-radius);
  }

  .card-back {
    background-color: var(--bs-body-bg);

    p {
      color: #576a74;
    }
  }

  .card-body {
    position: relative;
    padding: 0;
    background-color: #115173;
    border-bottom-left-radius: var(--bs-border-radius);
    border-bottom-right-radius: var(--bs-border-radius);

    .btn {
      position: absolute;
      bottom: 0;
      height: 40px;
      transition: all 1s ease 0s;

    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    li {
      margin-top: 5px;
      display: flex;
      align-items: center;
      padding: 2px 4px;
      background-color: #82a4b6;
      border: 1px solid #82a4b6;
      border-radius: 20px;
      font-size: 12px;
      margin-right: 4px;
      color: #000;

      svg {
        margin-right: 4px;
      }
      img {
        margin-right: 4px;
      }

    }
  }
}

@media (min-width: 400px) and (max-width: 576px) {
    .portfolio {
      .ratio-card {
        --bs-aspect-ratio: 90%;
      }
    }
}

@include media-breakpoint-up(md) {
  .portfolio {
    padding-top: 100px;
    padding-bottom: 100px;
    .ratio-card {
      --bs-aspect-ratio: 56.25%;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      width: 100%;
      height: auto;
    }

    .grid-item {
      background-color: #023048;
      aspect-ratio: 16/9;
    }

    .grid1 {
      grid-area: 1 / 1 / 2 / 2;
    }

    .grid2 {
      grid-area: 1 / 2 / 2 / 3;
    }

    .grid3 {
      grid-area: 2 / 1 / 3 / 2;
    }

    .grid4 {
      grid-area: 2 / 2 / 3 / 3;
    }

    .grid5 {
      grid-area: 3 / 1 / 4 / 2;
    }

    .grid6 {
      grid-area: 3 / 2 / 4 / 3;
    }

    .card:hover .card-inner {
      transform: rotateY(180deg);
    }
    .card-back {
      transform: rotateY(180deg);

      p {
        color: #576a74;
      }
    }

    .card-body {
      .btn:hover {
        height: 100%;
      }
    }
  }
}




