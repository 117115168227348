@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.home {
  height: calc(100vh - 330px);


  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }


  .row {
    position: relative;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      opacity: 0.6;
      top: -20px;
      left: 50px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      bottom: 0;
      left: 50px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }
  }

  .col-12 {
    padding-bottom: 50px;
  }

  .letterT {
    color: #ffd700;
  }


  .home-page {


    h1 {
      color: #fff;
      font-size: 56px;
      line-height: 53px;
      margin: 0;
      font-family: ' Open Sans ', sans-serif;
      font-weight:400;

      .letterT {
        margin-left: 20px;
        opacity: 0;
        width: 32px;
        height: auto;
        animation: rotateIn 1s linear both;
        animation-delay: 3s;
      }
    }

    h2 {
      color: #8d8d8d;
      margin-top: 20px;
      font-weight: 400;
      font-size: 11px;
      font-family: ' Open Sans ', sans-serif;
      letter-spacing: 4px;
      animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
      color: #ffd700;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 4px;
      font-family: ' Open Sans ', sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 25px;
      float: left;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
      max-width: 200px;
      text-align: center;
      transition-duration: 0.5s;

      &:hover {
        background: #ffd700;
        color: #333;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .home {
    height: calc(100vh - 130px);
  }
}


