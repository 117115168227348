

body {
  /*font: 300 11px/1.4 'Helvetica Neue', sans-serif;*/
  /*color: #444;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-bg: #022c43;
  /*overflow: hidden;*/
}


/*.dashboard {*/
/*  margin-top: 200px;*/
/*  margin-left: 200px;*/
/*}*/

