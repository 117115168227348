@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$color-cubeFace1: #dd0031;
$color-cubeFace2: #f06529;
$color-cubeFace3: #28a4d9;
$color-cubeFace4: #5ed4f4;
$color-cubeFace5: #efd81d;
$color-cubeFace6: #ec4d28;

.about {
  padding-top: 50px;
  padding-bottom: 50px;
  height: calc(100vh - 130px);


  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }


  .stage-cube-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
  }
  .stage-cube-cont {
    padding-top: 2rem;
    margin-bottom: 2rem;
  }
  p {
    letter-spacing: 1.5px;
  }


  .text-zone {
    padding-left: 50px;
  }

  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
    padding-top: 2rem;
    padding-bottom: 4rem;
    min-height: 300px;


    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      border-radius: 10px;
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cubeFace1 {
      transform: translateZ(100px);
      color: $color-cubeFace1;
    }
    .cubeFace2 {
      transform: rotateY(90deg) translateZ(100px);
      color: $color-cubeFace2;
    }
    .cubeFace3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: $color-cubeFace3;
    }
    .cubeFace4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: $color-cubeFace4;
    }
    .cubeFace5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: $color-cubeFace5;
    }
    .cubeFace6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: $color-cubeFace6;
    }
  }

  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }
}


@include media-breakpoint-up(md) {
  .stage-cube-cont {
    padding-top: 0;
  }
  .about {
    padding: 0;
  }
}
