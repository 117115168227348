@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.contact {
  height: auto;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    overflow: hidden;
  }

  .text-zone {
    position: relative;
    padding-left: 40px;
  }
  h1 {
    color: yellow;
    font-size: 4rem;
  }

  .contact-form {
    width: 100%;
    margin-top: 20px;


    ul {
      padding: 0;
      margin: 0;
      display: block;

      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 1s 1s;
        animation-fill-mode: forwards;
      }

      li.half {
        max-width: 500px;
        clear: none;
        display: block;
      }
    }

    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      font-family: ' Open Sans ', sans-serif;
    }

    textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
      font-family: ' Open Sans ', sans-serif;
    }

    .flat-button {
      color: #ffd700;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #ffd700;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
      font-family: ' Open Sans ', sans-serif;
    }
  }
  #map {
    padding-left: 40px;
    padding-bottom: 20px;
  }

  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }

  .info-map {
    position: absolute;
    background: #000;
    top: 50px;
    right: 30%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
      font-size: 16px;

      span {
        color: #ffd700;
      }
    }
  }

  .leaflet-container {
    width: 100%;
    height: 500px;
  }
}
@include media-breakpoint-up(md) {
  .contact {
    height: calc(100vh - 130px);
    min-height: 700px;
  }
}
